function month(month, lan) {
  switch (month) {
    case 0:
      return gettext('January')

    case 1:
      return gettext('February')

    case 2:
      return gettext('March')

    case 3:
      return gettext('April')

    case 4:
      return gettext('May')

    case 5:
      return gettext('June')

    case 6:
      return gettext('July')

    case 7:
      return gettext('August')

    case 8:
      return gettext('September')

    case 9:
      return gettext('October')

    case 10:
      return gettext('November')

    case 11:
      return gettext('December')
  }
}

const EventsByMonth = class {
  constructor() {
    this.rootEl = document.querySelector('#events')
    this.monthEl = document.querySelector('#events .month')
    this.eventsEl = document.querySelector('#events .events-container')
    this.bwdEl = document.querySelector('#events .arrow-left')
    this.fwdEl = document.querySelector('#events .arrow-right')

    this.init().then(() => {
      this.bwdEl.addEventListener('click', this.navCb.bind(this))

      this.fwdEl.addEventListener('click', this.navCb.bind(this))
    })
  }

  async init() {
    await this.initUrl()
    return this.initState()
  }

  async initUrl() {
    if (!this.getDateParams()) return null

    const dateData = this.getDateParams()

    const date = new Date()
    date.setDate(1)
    date.setMonth(dateData.month)
    date.setFullYear(dateData.year)

    const resRaw = await this.fetchByDate(date, this.month)
    const res = JSON.parse(resRaw)

    if (res.isInRange) return null

    history.pushState({}, '', '/')
    return true
  }

  // render current month data if present. Otherwise, cycle through the next months until finding data or coming back to this.month
  async initState() {
    const dateData = this.getDateParams()

    if (!dateData) {
      const res = await this.fetchClosest()

      if (!res.isInRange) return

      const date = new Date(res.date)

      this.year = date.getFullYear()
      this.month = date.getMonth()

      this.setNavNext()
      this.setNavPrev()

      return this.render(res.template)
    }

    this.year = dateData.year
    this.month = dateData.month

    this.setNavNext()
    this.setNavPrev()

    const date = new Date()
    date.setDate(1)
    date.setMonth(this.month)
    date.setFullYear(this.year)

    const resRaw = await this.fetchByDate(date, this.month)
    const res = JSON.parse(resRaw)

    if (!res.isData) {
      this.render(res.template)
      return
    }

    this.render(res.template, this.month)
  }

  getDateParams() {
    const date = this.doGetDateParams()

    if (!date) return null

    let _month = date.month,
      _year = date.year

    if (date.month > 11 || date.month < 0) {
      _month = 0
    }

    return {
      year: _year,
      month: _month,
    }
  }

  doGetDateParams() {
    if (!location.search.length) return null

    return this.parseDateParams(location.search.slice(1))
  }

  parseDateParams(paramsString) {
    const params = new URLSearchParams(paramsString)

    const year = parseInt(params.get('year'))
    const month = parseInt(params.get('month'))

    if (Number.isNaN(year) || Number.isNaN(month)) return null

    return { year, month }
  }

  async navCb(ev) {
    ev.preventDefault()

    if (!ev.target.getAttribute('data-params')) return

    history.pushState({}, '', `?${ev.target.getAttribute('data-params')}`)
    this.init()
  }

  async fetchByDate(date, month) {
    const body = new URLSearchParams()
    body.append('date', date.toISOString())

    const res = await fetch(`events-by-month?${body.toString()}`)
    const resBody = await res.text()

    return resBody
  }

  async fetchClosest() {
    const body = new URLSearchParams()
    body.append('getClosestToNow', 'true')

    const res = await fetch(`events-by-month?${body.toString()}`)
    const resBody = await res.json()

    return resBody
  }

  async setNavPrev() {
    const dateNext = this.decrementMonth(this.month, this.year)

    const date = new Date()
    date.setDate(1)
    date.setFullYear(dateNext.year)
    date.setMonth(dateNext.month)

    const resRaw = await this.fetchByDate(date)
    const res = JSON.parse(resRaw)

    if (!res.isInRange) {
      this.bwdEl.removeAttribute('data-params')

      this.bwdEl.setAttribute('href', '/#')
      this.bwdEl.setAttribute('rel', 'nofollow')

      return
    }

    const dateParams = new URLSearchParams()
    dateParams.append('year', dateNext.year)
    dateParams.append('month', dateNext.month)

    this.bwdEl.removeAttribute('rel')
    this.bwdEl.setAttribute('href', `/?${dateParams.toString()}`)
    this.bwdEl.setAttribute('data-params', dateParams.toString())
  }

  async setNavNext() {
    const dateNext = this.incrementMonth(this.month, this.year)

    const date = new Date()
    date.setDate(1)
    date.setFullYear(dateNext.year)
    date.setMonth(dateNext.month)

    const resRaw = await this.fetchByDate(date)
    const res = JSON.parse(resRaw)

    if (!res.isInRange) {
      this.fwdEl.removeAttribute('data-params')

      this.fwdEl.setAttribute('href', '/#')
      this.fwdEl.setAttribute('rel', 'nofollow')

      return
    }

    const dateParams = new URLSearchParams()
    dateParams.append('year', dateNext.year)
    dateParams.append('month', dateNext.month)

    this.fwdEl.removeAttribute('rel')
    this.fwdEl.setAttribute('href', `/?${dateParams.toString()}`)
    this.fwdEl.setAttribute('data-params', dateParams.toString())
  }

  incrementMonth(month, year) {
    let _month = null,
      _year = null

    if (month === 11) {
      _year = year + 1
      _month = 0
    } else {
      _year = year
      _month = month + 1
    }

    return { month: _month, year: _year }
  }

  decrementMonth(month, year) {
    let _month = null,
      _year = null

    if (month === 0) {
      _year = year - 1
      _month = 11
    } else {
      _year = year
      _month = month - 1
    }

    return { month: _month, year: _year }
  }

  render(htmlText) {
    if (this.rootEl.classList.contains('noned')) this.rootEl.classList.remove('noned')

    this.monthEl.innerText = month(this.month)
    this.eventsEl.innerHTML = htmlText
  }
}

export default EventsByMonth
