import parseisotime from 'parseisotime'
import baguetteBox from 'baguettebox.js'
import { Swiper, Pagination, Navigation, FreeMode } from 'swiper'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import ukLocale from '@fullcalendar/core/locales/uk'
import enLocale from '@fullcalendar/core/locales/en-gb'

import EventsByMonth from './events-by-month'

const _l = any => {
  console.log(any)
  return any
}

const Slovo = class {
  init() {
    this.handleInit()
    window.addEventListener('load', this.handleWindowLoad.bind(this))
  }

  handleInit() {
    if (this.isPageType('about') || this.isPageType('headline')) this.richContentExposeMedia()
  }

  handleWindowLoad() {
    this.navigation()

    if (this.isPageType('landing')) {
      this.landingCalendar()
      this.landingSwiper()
      this.eventsByMonth()
    }

    if (this.isPageType('show') || this.isPageType('gallery') || this.isPageType('headline')) {
      this.gallery()
    }
  }

  isPageType(type) {
    return document.body.classList.contains(`page-type-${type}`)
  }

  navigation() {
    const toggler = document.querySelector('.mobile-nav-toggler')
    const menu = document.querySelector('.mobile-nav')
    const links = menu.querySelectorAll('a')
    toggler.addEventListener('click', function (ev) {
      const is_expanded = this.getAttribute('aria-expanded') == 'true'
      if (is_expanded) {
        menu.setAttribute('hidden', '')
        this.setAttribute('aria-expanded', 'false')
        document.body.classList.remove('mobile-overlay')
      } else {
        menu.removeAttribute('hidden')
        this.setAttribute('aria-expanded', 'true')
        document.body.classList.add('mobile-overlay')
      }
    })
    links.forEach(l =>
      l.addEventListener('click', function () {
        const ev = new Event('click')
        toggler.dispatchEvent(ev)
      })
    )
  }

  gallery() {
    const selector = '.gallery'
    baguetteBox.run(selector)
  }

  async landingCalendar() {
    const eventsData = await fetch('events-json')
    const events = await eventsData.json()

    const eventsSource = events.result.map(e => {
      const date = parseisotime(e.fields.datetime)

      return {
        start: date.date,
        end: date.date,
        url: `events/${e.fields.show}`,
      }
    })

    const locale = location.pathname.slice(1).split('/').shift()

    const calendar = new Calendar(document.querySelector('.calendar-container'), {
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',
      events: eventsSource,
      headerToolbar: {
        start: 'prev',
        center: 'title',
        end: 'next',
      },
      titleFormat: {
        month: 'long',
      },
      locale: 'uk' === locale ? ukLocale : enLocale,
      height: 345,
      // contentHeight: 380
    })

    calendar.render()

    console.log('landingCalendar, calendar:', calendar)
  }

  landingSwiper() {
    if (!document.querySelectorAll('.swiper-wrapper > .swiper-slide').length) return

    const renderName = swiper => {
      const name = Array.from(document.querySelector('.swiper .swiper-wrapper').children)[
        swiper.activeIndex
      ].getAttribute('data-name')
      document.querySelector('.swiper .swiper-name').innerText = name
    }

    document.querySelector('.swiper').classList.remove('swiper-empty')

    const swiper = new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: document.querySelector('.swiper .btn-next-container'),
        prevEl: document.querySelector('.swiper .btn-prev-container'),
      },
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        activeIndexChange: renderName,
        init: renderName,
      },
    })

    console.log('landingSwiper, swiper:', swiper)
  }

  eventsByMonth() {
    this.eventsByMonthInstance = new EventsByMonth()
    console.log('EventsByMonth', this.eventsByMonthInstance)
  }

  // remove p wrappers around img and iframe, produced by TinyMCE
  richContentExposeMedia() {
    const containers = Array.from(document.querySelectorAll('.rich-content'))

    for (const container of containers) {
      const media = container.querySelectorAll('p img, p iframe')

      if (!media.length) continue

      for (const el of media) {
        el.parentElement.replaceWith(el)
      }
    }
  }
}

const slovo = new Slovo()
slovo.init()
console.log(slovo)
